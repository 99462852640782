import React from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from 'rebass'
import Header from './Header'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Box variant="layout">{children}</Box>
    </>
  )
}

export default Layout
